<template>
    <div>
        <bread-crumb :crumbData="['销售订单', '订单详情']" :chooseCrumbData="2" @close="onClose"></bread-crumb>
        <div style="padding: 20px; font-size: 14px;">
            <div style="text-align: right;">
                <el-button @click="closeOrder" type="success" size="mini">订单关闭</el-button>
                <el-button @click="send" type="success" size="mini">订单发货</el-button>
                <el-button @click="refund" type="success" size="mini">订单退款</el-button>
                <el-button type="primary" size="mini">打印</el-button>
            </div>
            <!-- 导航栏 -->
            <div class="wrapper">
                <input type="radio" name="tab" id="home" checked>
                <input type="radio" name="tab" id="user">
                <input type="radio" name="tab" id="message">
                <input type="radio" name="tab" id="collect">
                <input type="radio" name="tab" id="create">
                <label for="home" class="home" @click="show = 1">订单信息</label>
                <label for="user" class="user" @click="show = 2">收款信息</label>
                <label for="message" class="message" @click="show = 3">退款信息</label>
                <label for="collect" class="collect" @click="show = 4">回桶信息</label>
                <label for="create" class="create" @click="show = 5">订单日志</label>
            </div>
            <!-- 订单信息 -->
            <div style="font-size: 15px" v-show="show == 1">
                <p style="text-align: center;font-size: 30px;margin-top: 20px">天下行水超市【广体服务中心】销售订单</p>
                <div style="display: flex; justify-content: space-around; margin: 10px 5px 5px 5px">
                    <div>
                        <!-- <p>客户名称：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info1.consignee }}</p> -->
                        <p>收货信息：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info1.region +
                            info1.location }}</p>
                        <!-- <p><span style="width: 95px; display: inline-flex">&emsp;</span>{{ "广东广州天河广州大道中1268广州体育学院7号楼1层153"
                        }}</p> -->
                        <p>买家留言：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ "202401033333312" }}</p>
                        <p>送货留言：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ "" }}</p>
                        <p><span style="width: 20px; display: inline-flex">&emsp;</span>{{ "" }}</p>
                    </div>
                    <div>
                        <p>&emsp;订单号：<span style="width: 20px; display: inline-flex">&emsp;</span>{{
                            info1.order_number }}
                        </p>
                        <p>制单日期：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info1.create_time }}
                        </p>
                        <p>预计送达：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info1.service_time }}
                        </p>
                        <p>支付方式：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info1.payment }}</p>
                        <p>订单状态：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info1.pay_status }}
                        </p>
                        <p>订单来源：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info1.ordering_method
                        }}</p>
                    </div>
                </div>
                <table class="table">
                    <tr>
                        <th v-for="(item, index) in tableth1" :key="index">{{ item }}</th>
                    </tr>
                    <tr v-for="(item, index) in tabletd1" :key="index">
                        <td>{{ item.product_name }}</td>
                        <td>{{ item.specifications }}</td>
                        <td>{{ item.price }} </td>
                        <td>{{ item.coupon }}</td>
                        <td>{{ item.number }} </td>
                        <td>{{ item.number }}</td>
                        <td>{{ item.sales }}</td>
                    </tr>
                    <tr v-show="tabletd1.length == 0">
                        <td :colspan="7" style="color: #666">没有数据</td>
                    </tr>
                    <tr>
                        <td :colspan="3">合计</td>
                        <td>{{ totalNumber }}</td>
                        <td>{{ total }}</td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
                <div style="display: flex; justify-content: space-around; margin: 10px 5px 5px 5px">
                    <div>
                        <p>&emsp;销售站：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info1.station }}
                        </p>
                        <p>&emsp;配送站：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info1.station }}
                        </p>
                        <p>&emsp;送货员：<span style="width: 20px; display: inline-flex">&emsp;</span>{{
                            info1.delivery }}</p>
                        <p>&emsp;推广人：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info1.referrer
                        }}
                        </p>
                        <p>预约时间：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ "" }}</p>
                    </div>
                    <div>
                        <p>支付状态：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info1.pay_status }}
                        </p>
                        <p>应收金额：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info1.sum }}</p>
                        <p>实付金额：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info1.sum }}</p>
                    </div>
                </div>
            </div>
            <!-- 收款信息 -->
            <table class="table" v-show="show == 2">
                <tr>
                    <th v-for="(item, index) in tableth2" :key="index">{{ item }}</th>
                </tr>
                <tr v-if="info2">
                    <td>{{ info2.pay_time }}</td>
                    <td>{{ info2.pay_number }}</td>
                    <td>{{ info2.payment }} </td>
                    <td>{{ info2.sum }}</td>
                    <td>{{ info2.pay_status }} </td>
                </tr>
                <!-- <tr v-show="tabletd2.length == 0">
                    <td :colspan="7" style="color: #666">没有数据</td>
                </tr> -->
            </table>
            <!-- 退款信息 -->
            <table class="table" v-show="show == 3">
                <tr>
                    <th v-for="(item, index) in tableth3" :key="index">{{ item }}</th>
                </tr>
                <tr v-if="info3">
                    <td>{{ info3.refund_time }}</td>
                    <td>{{ info3.pay_number }}</td>
                    <td>{{ info3.payment }} </td>
                    <td>{{ info3.sum }}</td>
                    <td>{{ info3.order_status }} </td>
                </tr>
                <!-- <tr v-show="tabletd3.length == 0">
                    <td :colspan="7" style="color: #666">没有数据</td>
                </tr> -->
            </table>
            <!-- 回桶信息 -->
            <table class="table" v-show="show == 4">
                <tr>
                    <th v-for="(item, index) in tableth4" :key="index">{{ item }}</th>
                </tr>
                <tr v-if="info4">
                    <td>{{ info4.pay_time }}</td>
                    <td>{{ info4.pay_number }}</td>
                    <td>{{ info4.payment }} </td>
                    <td>{{ info4.sum }}</td>
                    <td>{{ info4.pay_status }} </td>
                </tr>
                <!-- <tr v-show="tabletd4.length == 0">
                    <td :colspan="7" style="color: #666">没有数据</td>
                </tr> -->
            </table>
            <!-- 订单日志 -->
            <table class="table" v-show="show == 5">
                <tr>
                    <th v-for="(item, index) in tableth5" :key="index">{{ item }}</th>
                </tr>
                <tr v-for="(item, index) in info5" :key="index">
                    <td>{{ item.create_time }}</td>
                    <td>{{ item.types }}</td>
                    <td>{{ item.operator }} </td>
                    <td>{{ item.notes }}</td>
                </tr>
                <!-- <tr v-show="tableth5Info">
                    <td :colspan="7" style="color: #666">没有数据</td>
                </tr> -->
            </table>
        </div>
    </div>
</template>
<script>
import { refund, send, closeOrder, order_details, order_pay, refunds, back_barrel, log } from "@/api/salesManagement/salesOrder.js";
export default {
    props: ["onClose"],
    async created() {
        // console.log(refund, send, closeOrder, order_details, order_pay, refunds, back_barrel, log)
    },
    async updated() {
        // console.log(this.tableData.id)
    },
    data() {
        return {
            // 父组件值
            tableData: {},
            show: 1,
            // 表头1
            tableth1: ["商品名称", "规格", "商品价格", "数量", "金额", "发货数量", "退货数量"],
            // 表格内容1
            tabletd1: [],
            // 总订货数量
            info1: null,
            totalNumber: 0,
            // 合计价格
            total: 0,
            tableth2: ["付款日期", "编号", "方式", "付款金额", "状态"],
            info2: null,
            tabletd2: [],
            tableth3: ["退款日期", "编号", "方式", "退款金额", "状态"],
            tabletd3: [],
            info3: null,
            tableth4: ["品牌", "送出", "收回"],
            tabletd4: [],
            info4: null,
            tableth5: ["创建日期", "类型", "操作员", "内容"],
            tabletd5: [],
            info5: null,
        }
    },
    watch: {
        tabletd1() { this.totalUpdate() },
        async 'tableData'({ id }, o) {
            this.info1 = (await order_details(id))?.data;
            this.info2 = (await order_pay(id))?.data;
            try {
                this.info3 = (await refunds(id))?.data;
            } catch (error) {
                console.log("shibai")
            }
            // this.info4 = (await back_barrel(id)).data;
            this.info5 = (await log(id))?.data;
            // back_barrel(id).then(({ data }) => {
            //     this.info4 = data
            // });
            // log(id).then(({ data }) => {
            //     this.info5 = data
            // });
        }
    },
    methods: {
        // onClose(data) {
        //     this.$emit("close", data);
        // },
        // 计算合计价格
        totalUpdate() {
            this.totalNumber = this.tabletd1.reduce((sum, e) => { return sum + Number(e.number) }, 0)
            this.total = this.tabletd1.reduce((sum, e) => sum + Number(e.price * e.number - e.coupon), 0)
        },
        // 关闭订单
        closeOrder() {
            this.$confirm('是否关闭订单', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                closeOrder(this.tableData.id).then((res) => {
                    if (res.status == 200) {
                        this.$message({
                            message: "提交成功",
                            type: "success",
                        });
                    }
                });
            }).catch(() => {
                return false
            });
        },
        // 订单发货
        send() {

            this.$confirm('确定订单发货', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                send(this.tableData.id).then((res) => {
                    if (res.status == 200) {
                        this.$message({
                            message: "提交成功",
                            type: "success",
                        });
                    }
                });
            }).catch(() => {
                return false
            });
        },
        // 关闭订单
        refund() {
            this.$confirm('确定关闭订单', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                refund(this.tableData.id).then((res) => {
                    if (res.status == 200) {
                        this.$message({
                            message: "提交成功",
                            type: "success",
                        });
                    }
                });
            }).catch(() => {
                return false
            });
        }
    }
}
</script>
<style scoped lang="less">
p {
    margin: 5px
}

.wrapper {
    display: flex;
    border-bottom: 1px solid #000;
}

.wrapper input {
    display: none;
}

.wrapper label {
    display: inline-block;
    padding: 0 15px;
    background-color: rgb(231, 231, 231);
    color: rgb(0, 0, 0);
    font-weight: bold;
    height: 30px;
}

#home:checked~label.home,
#user:checked~label.user,
#message:checked~label.message,
#collect:checked~label.collect,
#create:checked~label.create {
    color: #fff;
    background-color: rgb(0, 0, 0);
}

.table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;

    th {
        padding: 10px 20px;
        border: 1px solid #e5e5e5;
    }

    td {
        padding: 10px 20px;
        border: 1px solid #e5e5e5;
    }
}
</style>